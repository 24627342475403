import axios from "@/axios/index.js";

const validationRoute = (to, from, next) => {
  axios
    .get(`booking`)
    .then(() => {
      next();
    })
    .catch(() => {
      alert("Realize o login para acessar está página!");
      next("/login");
    });
};

export default { validationRoute };
