import Vue from "vue";
import VueRouter from "vue-router";
import guard from "@/guard/routeGuard.js";
const { validationRoute } = guard;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/LandingPage.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/components/modules/landing-page/Home.vue"),
      },
    ],
  },
  {
    path: "/painel",
    component: () => import("@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/components/modules/dashboard/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/reservar",
    component: () => import("@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "Reservar",
        component: () =>
          import("@/components/modules/booking/BookingInfos.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/components/modules/login/Login.vue"),
      },
    ],
  },
  {
    path: "/painel/calendario",
    beforeEnter: validationRoute,
    component: () => import("@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "Calendário",
        component: () => import("@/components/modules/booking/Calendar.vue"),
      },
    ],
  },
  {
    path: "/painel/gerenciar",
    beforeEnter: validationRoute,
    component: () => import("@/views/Page.vue"),
    children: [
      {
        path: "",
        name: "Gerenciar",
        component: () => import("@/components/modules/manager/Manager.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
