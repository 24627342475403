import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    booking: {
      start_date: "",
      end_date: "",
      all_dates: [],
      value: "",
      amount_of_people: "",
      amount_children: "",
      house_rules: "",
      privacity_term: "",
    },
  },

  getters: {
    getBooking(state) {
      return state.booking;
    },
  },

  mutations: {
    setBooking(state, data) {
      state.booking = {
        start_date: data ? data.start_date : "",
        end_date: data ? data.end_date : "",
        all_dates: data ? data.all_dates : [],
        value: data ? data.value : "",
        amount_of_people: data ? data.amount_of_people : "",
        amount_children: data ? data.amount_children : "",
        house_rules: data ? data.house_rules : "",
        privacity_term: data ? data.house_rules : "",
      };
    },
  },

  actions: {
    changeBooking(context, data) {
      context.commit("setBooking", data);
    },
  },
});
